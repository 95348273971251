import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0acd4fe3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0acd4fe3&prod&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&id=0acd4fe3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0acd4fe3",
  null
  
)

export default component.exports