import { http } from '@/http/axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { setCookie } from '@/utils/support'
import { asyncRoutes, constantRoutes, anyRoutes } from '@/router/routes'
import { router } from '@/router'

const computeAsyncRoutes = (oriAsyncRoutes, authMenus = []) => {
  return oriAsyncRoutes.filter(item => {
    if (authMenus.indexOf(item.name) != -1) {
      if (item.children && item.children.length > 0) {
        item.children = computeAsyncRoutes(item.children, authMenus)
      }
      return true
    }
  })
}

const user = {
  state: {
    token: getToken(),
    userInfo: {
      account: ''
    },
    authMenus: [],
    isAdmin: 0
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },

    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_IS_ADMIN: (state, isAdmin) => {
      state.isAdmin = isAdmin
    },
    SET_APPID: (state, appid) => {
      state.appid = appid
    },
    SET_AUTHMENUS(state, val) {
      state.authMenus = val
    }
  },

  getters: {
    isAdmin(state) {
      return state.isAdmin
    },
    authBtns(state) {
      return state.authMenus
    },
    getUserInfo: state => state.useInfo,

    getResultRoutes: state => {
      const calAsyncRoutes = state.isAdmin ? asyncRoutes() : computeAsyncRoutes(asyncRoutes(), state.authMenus)
      let res = constantRoutes.concat(calAsyncRoutes, anyRoutes).filter(item => item.path)
      res.forEach(item => {
        if (item.children && item.children.length > 0) {
          for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].meta && !item.children[i].meta.hidden) {
              item.redirect = item.children[i].path
              break
            }
          }
        }
      })
      router.addRoutes(res)
      return res
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.account.trim()
      return new Promise((resolve, reject) => {
        http({
          url: '/manage/passport/misc/login',
          method: 'POST',
          data: {
            account: username,
            password: userInfo.password
            // user_type: 1
          }
        })
          .then(response => {
            const tokenStr = response.token
            setToken(tokenStr)
            commit('SET_TOKEN', tokenStr)
            commit('SET_USER_INFO', { account: response.loginAccount })
            commit('SET_IS_ADMIN', 1)
            commit('SET_AUTHMENUS', [])
            // commit('SET_RESULT_ASYNC_ROUTES', isAdmin ? asyncRoutes : computeAsyncRoutes(asyncRoutes, []))
            commit('SET_APPID', response.appid)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
      // return new Promise((resolve, reject) => {
      //   http({
      //     url: '/company/passport',
      //     method: 'DELETE'
      //   })
      //     .then(() => {
      //       commit('SET_TOKEN', '')
      //       commit('SET_ROLES', [])
      //       removeToken()
      //       resolve()
      //     })
      //     .catch(error => {
      //       reject(error)
      //     })
      // })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_APPID', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
