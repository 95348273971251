<template>
  <div :class="['mask', show_btn ? '' : 'close-btn']" @mouseenter="show_btn = true">
    <div :class="['warn-btn']">
      <span @click="changeStart">
        <i :class="[isStart ? 'el-icon-video-pause' : 'el-icon-video-play']" :title="isStart ? '关闭提示音' : '开启提示音'"></i>
      </span>
      <span @click="show_btn = false">
        <i class="el-icon-error" title="隐藏按钮"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'TipAudio',
  props: {
    isTip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show_btn: true,
      isStart: false,
      timer: null,
      audio: new Audio(),
      customer_moment_audio: require('@/assets/audio/notify.mp3')
    }
  },
  computed: {
    // ...mapState('moments', ['customer_moment', 'customer_audit', 'chat_audit']),
    // ...mapGetters('moments', ['getAuditTotal'])
  },
  watch: {
    isTip: {
      immediate: true,
      handler(newV, oldV) {
        if (newV) {
          this.isStart = true
        } else {
          this.isStart = false
        }
      }
    },
    isStart: {
      immediate: true,
      handler(newV, oldV) {
        if (newV) {
          this.handleStartRepeat()
        } else {
          clearInterval(this.timer)
        }
      }
    }
  },
  mounted() {},

  methods: {
    changeStart() {
      console.log('点击按钮')
      this.isStart = !this.isStart
    },
    startAudio() {
      console.log('开始播放')
      this.audio.autoplay = true
      this.audio.src = this.customer_moment_audio
    },
    handleStartRepeat() {
      // this.startAudio()
      this.timer = setInterval(() => {
        this.startAudio()
      }, 5000)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.mask {
  transition: all 0.3s linear;
  position: fixed;
  z-index: 1000;
  top: 85%;
  right: 20px;
  width: 80px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0);
  .warn-btn {
    position: absolute;
    // z-index: 1000;
    // top: 80%;
    right: 0px;
    width: 35px;
    height: 70px;
    // border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    i {
      transition: all 0.3 linear;
      font-size: 18px;
      color: #409eff;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50%;
      width: 100%;
      &:hover {
        i {
          transform: scale(1.2);
        }
      }
      &:first-child {
        border-bottom: 1px solid #ccc;
      }
      &:last-child {
        i {
          color: #ccc;
        }
      }
    }
  }
}
.close-btn {
  transition: all 0.3s linear;
  right: -50px;
}
</style>
