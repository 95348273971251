// 常量路由

import systemManageRouter from '@/router/modules/systemManage'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '首页'
    },
    component: () => import('../views/home/index.vue')
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  // 活动管理
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('@/views/activity/index.vue'),
    meta: { name: '活动管理', icon: 'el-icon-present' },
    children: [
      {
        path: '/activity/activity-list/list',
        name: 'ActivityList',
        component: () => import('@/views/activity/activity-list/list.vue'),
        meta: { name: '活动列表', keepAlive: true }
      },
      // {
      //   path: '/activity/required/list',
      //   name: 'ActivityRequiredList',
      //   component: () => import('@/views/activity/activity-required/list.vue'),
      //   meta: { name: '活动要求', keepAlive: true }
      // },
      // {
      //   path: '/activity/required/add',
      //   name: 'ActivityRequiredAdd',
      //   component: () => import('@/views/activity/activity-required/update.vue'),
      //   meta: { name: '新增活动要求', hidden: true, activeMenu: '/activity/required/list' }
      // },
      // {
      //   path: '/activity/required/edit/:id?',
      //   name: 'ActivityRequiredEdit',
      //   component: () => import('@/views/activity/activity-required/update.vue'),
      //   meta: { name: '编辑活动要求', hidden: true, activeMenu: '/activity/required/list' }
      // },

      {
        path: '/activity/group-type/list',
        name: 'GroupTypeList',
        component: () => import('@/views/activity/group-type/list.vue'),
        meta: { name: '组局类型', keepAlive: true }
      },
      {
        path: '/activity/group-type/add',
        name: 'GroupTypeAdd',
        component: () => import('@/views/activity/group-type/update.vue'),
        meta: {
          name: '新增组局类型',
          hidden: true,
          activeMenu: '/activity/group-type/list'
        }
      },
      {
        path: '/activity/group-type/edit/:id?',
        name: 'GroupTypeEdit',
        component: () => import('@/views/activity/group-type/update.vue'),
        meta: {
          name: '编辑组局类型',
          hidden: true,
          activeMenu: '/activity/group-type/list'
        }
      },
      // {
      //   path: '/activity/group-select/list',
      //   name: 'GroupSelectList',
      //   component: () => import('@/views/activity/group-select/list.vue'),
      //   meta: { name: '组局选项', keepAlive: true }
      // },
      // {
      //   path: '/activity/group-select/add',
      //   name: 'GroupSelectAdd',
      //   component: () => import('@/views/activity/group-select/update.vue'),
      //   meta: { name: '新增组局选项', hidden: true, activeMenu: '/activity/group-select/list' }
      // },
      // {
      //   path: '/activity/group-select/edit/:id?',
      //   name: 'GroupSelectEdit',
      //   component: () => import('@/views/activity/group-select/update.vue'),
      //   meta: { name: '编辑组局选项', hidden: true, activeMenu: '/activity/group-select/list' }
      // },
      {
        path: '/activity/jubao/list',
        name: 'JubaoList',
        component: () => import('@/views/activity/jubao/list.vue'),
        meta: { name: '举报列表', keepAlive: true }
      },
      {
        path: '/activity/fankui/list',
        name: 'FankuiList',
        component: () => import('@/views/activity/fankui/list.vue'),
        meta: { name: '反馈列表', keepAlive: true }
      },
      {
        path: '/activity/apply/list',
        name: 'ApplyList',
        component: () => import('@/views/activity/apply/list.vue'),
        meta: { name: '活动申请', keepAlive: true }
      },
      {
        path: '/activity/rate/list',
        name: 'RateList',
        component: () => import('@/views/activity/rate/list.vue'),
        meta: { name: '活动评价', keepAlive: true }
      }
    ]
  },
  // 订单管理
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: { name: '订单管理', icon: 'el-icon-s-order' },
    children: [
      {
        path: '/order/recharge-list/list',
        name: 'RechargeList',
        component: () => import('@/views/order/recharge-list/list.vue'),
        meta: { name: '充值订单', keepAlive: true }
      },
      {
        path: '/order/recharge-radio-type/list',
        name: 'RechargeRadioType',
        component: () => import('@/views/order/recharge-radio-type/list.vue'),
        meta: { name: '充值比例' }
      },
      {
        path: '/order/withdraw-list/list',
        name: 'WithdrawList',
        component: () => import('@/views/order/withdraw-list/list.vue'),
        meta: { name: '提现列表' }
      }
    ]
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import('@/views/finance/index.vue'),
    meta: { name: '财务管理', icon: 'el-icon-notebook-2' },
    children: [
      {
        path: '/finance/bill/list',
        name: 'BillList',
        component: () => import('@/views/finance/bill/list.vue'),
        meta: { name: '平台账单' }
      }
    ]
  },
  // 用户管理
  {
    path: '/customer',
    name: 'Cusomer',
    component: () => import('@/views/customer/index.vue'),
    meta: { name: '用户管理', icon: 'el-icon-user-solid' },
    children: [
      {
        path: '/customer/customer-list/list',
        name: 'CustomerList',
        component: () => import('@/views/customer/customer-list/list.vue'),
        meta: { name: '用户列表', keepAlive: true }
      },
      {
        path: '/customer/vip-set/list',
        name: 'VipSetList',
        component: () => import('@/views/customer/vip-set-list/list.vue'),
        meta: { name: '会员体系', keepAlive: true }
      },
      {
        path: '/customer/review/list',
        name: 'CustomerReview',
        component: () => import('@/views/customer/review-list/list.vue'),
        meta: { name: '资料审核', keepAlive: true }
      }
      // {
      //   path: '/customer/bill/list',
      //   name: 'CustomerBill',
      //   component: () => import('@/views/customer/customer-bill/list.vue'),
      //   meta: { name: '用户流水', keepAlive: true }
      // }
    ]
  },
  // 动态圈
  {
    path: '/dynamic',
    name: 'Dynamic',
    component: () => import('@/views/dynamic/index.vue'),
    meta: { name: '动态圈', icon: 'el-icon-s-comment' },
    children: [
      {
        path: '/dynamic/dynamic-list/list',
        name: 'DynamicList',
        component: () => import('@/views/dynamic/dynamic-list/list.vue'),
        meta: { name: '动态列表', keepAlive: true }
      }
    ]
  },
  // 商户管理
  // {
  //   path: '/merchant',
  //   name: 'Merchant',
  //   component: () => import('@/views/merchant/index.vue'),
  //   meta: { name: '商户管理', icon: 'el-icon-school' },
  //   children: [
  //     {
  //       path: '/merchant/merchant-list/list',
  //       name: 'MerchantList',
  //       component: () => import('@/views/merchant/merchant-list/list.vue'),
  //       meta: { name: '商户列表', keepAlive: true }
  //     }
  //   ]
  // },
  // 群组
  {
    path: '/group',
    name: 'Group',
    component: () => import('@/views/group/index.vue'),
    meta: { name: '兴趣群组', icon: 'el-icon-mic' },
    children: [
      {
        path: '/group/list',
        name: 'GroupList',
        component: () => import('@/views/group/group-list/list.vue'),
        meta: { name: '群组列表', keepAlive: true }
      }
    ]
  },
  // 探店
  {
    path: '/td',
    name: 'Td',
    component: () => import('@/views/td/index.vue'),
    meta: { name: '探店', icon: 'el-icon-mic' },
    children: [
      {
        path: '/td/shop-category/list',
        name: 'TdShopCategoryList',
        component: () => import('@/views/td/shop-category/list.vue'),
        meta: { name: '店铺类型', keepAlive: true }
      },
      {
        path: '/td/shop-category/add',
        name: 'TdShopCategoryAdd',
        component: () => import('@/views/td/shop-category/update.vue'),
        meta: {
          name: '新增店铺类型',
          hidden: true,
          activeMenu: '/td/shop-category/list'
        }
      },
      {
        path: '/td/shop-category/edit/:id?',
        name: 'TdShopCategoryEdit',
        component: () => import('@/views/td/shop-category/update.vue'),
        meta: {
          name: '编辑店铺类型',
          hidden: true,
          activeMenu: '/td/shop-category/list'
        }
      },
      {
        path: '/td/shop/list',
        name: 'TdShopList',
        component: () => import('@/views/td/shop-list/list.vue'),
        meta: { name: '商户列表', keepAlive: true }
      },
      {
        path: '/merchant/merchant-list/list',
        name: 'MerchantList',
        component: () => import('@/views/merchant/merchant-list/list.vue'),
        meta: { name: '商户入驻申请', keepAlive: true }
      },
      {
        path: '/td/shop/add',
        name: 'TdShopAdd',
        component: () => import('@/views/td/shop-list/update.vue'),
        meta: {
          name: '新增商户',
          hidden: true,
          activeMenu: '/td/shop/list'
        }
      },
      {
        path: '/td/shop/edit/:id?',
        name: 'TdShopEdit',
        component: () => import('@/views/td/shop-list/update.vue'),
        meta: {
          name: '编辑商户',
          hidden: true,
          activeMenu: '/td/shop/list'
        }
      },
      {
        path: '/td/shop-product/list',
        name: 'TdShopProductList',
        component: () => import('@/views/td/shop-product/list.vue'),
        meta: { name: '商品管理', keepAlive: true }
      },
      {
        path: '/td/shop-product/add',
        name: 'TdShopProductAdd',
        component: () => import('@/views/td/shop-product/update.vue'),
        meta: {
          name: '新增商品',
          hidden: true,
          activeMenu: '/td/shop-product/list'
        }
      },
      {
        path: '/td/shop-product/edit/:id?',
        name: 'TdShopProductEdit',
        component: () => import('@/views/td/shop-product/update.vue'),
        meta: {
          name: '编辑商品',
          hidden: true,
          activeMenu: '/td/shop-product/list'
        }
      },
      {
        path: '/td/order/list',
        name: 'TdOrderList',
        component: () => import('@/views/td/order-list/list.vue'),
        meta: { name: '订单列表', keepAlive: true }
      }
    ]
  },
  systemManageRouter
]
